export const DEFAULT_MATERIAL_ICON_MARKER: { icon: google.maps.Symbol; label: google.maps.MarkerLabel } = {
  icon: {
    path: 0.0, // circle
    fillColor: '#6F6F6F',
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: '#FFFFFF',
    scale: 15,
  },
  label: {
    text: '\ue0c8', // codepoint from https://fonts.google.com/icons
    fontFamily: 'Material Symbols Outlined',
    fontSize: '18px',
    color: '#FFFFFF',
  },
};

export const DEFAULT_ACTIVE_MATERIAL_ICON_MARKER: { icon: google.maps.Symbol; label: google.maps.MarkerLabel } = {
  ...DEFAULT_MATERIAL_ICON_MARKER,
  icon: {
    ...DEFAULT_MATERIAL_ICON_MARKER.icon,
    fillColor: '#303030',
  },
};
