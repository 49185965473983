import { FlightsView } from '../models/flights-view.model';
import { ExperienceStatus } from '../../models/experience-status.model';

export const PAIRED_FLIGHTS_VIEW_MOCK: FlightsView = {
  main: {
    outbound: {
      id: 333318,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Madrid to Mallorca',
      duration: '45m',
      dayDifference: 0,
      flightLegs: [
        {
          number: 'F1234',
          cabin: '',
          airlineName: 'Iberia',
          flightDuration: '2h 0m',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            datetime: '2024-02-04T12:00:00',
            terminal: 'T4',
          },
          arrival: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T14:00:00',
          },
        },
      ],
    },
    inbound: {
      id: 333295,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Mallorca to Madrid',
      duration: '1d 7h 45m',
      dayDifference: 0,
      flightLegs: [
        {
          number: 'F9876',
          cabin: '',
          airlineName: 'Iberia',
          flightDuration: '1h 0m',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T12:00:00',
          },
          arrival: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            terminal: 'T4',
            datetime: '2024-02-05T13:00:00',
          },
        },
      ],
    },
    localPriceWithCurrency: '$0.00',
    priceWithCurrency: '$0.00',
  },
  alternatives: [
    {
      outbound: {
        id: 333323,
        parentId: 333295,
        status: ExperienceStatus.CONFIRMED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333321,
        parentId: 333295,
        flightLegs: [],
        status: ExperienceStatus.PROPOSAL,
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333324,
        parentId: 333295,
        status: ExperienceStatus.SUGGESTION,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333322,
        parentId: 333295,
        status: ExperienceStatus.CANCELED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
  ],
};

export const ONE_WAY_FLIGHTS_VIEW_MOCK: FlightsView = {
  main: {
    outbound: {
      id: 333295,
      parentId: null,
      status: ExperienceStatus.PROPOSAL,
      title: 'From Mallorca to Madrid',
      duration: '1d 7h 45m',
      dayDifference: 0,
      flightLegs: [
        {
          number: 'F9876',
          cabin: '',
          airlineName: 'Iberia',
          flightDuration: '1h 0m',
          layoverDuration: null,
          travelers: [],
          departure: {
            airport: {
              name: 'Palma De Mallorca Airport',
              iata: 'PMI',
            },
            terminal: 'T1',
            datetime: '2024-02-05T12:00:00',
          },
          arrival: {
            airport: {
              name: 'Adolfo Suárez Madrid Barajas Airport',
              iata: 'MAD',
            },
            terminal: 'T4',
            datetime: '2024-02-05T13:00:00',
          },
        },
      ],
    },
    inbound: null,
    localPriceWithCurrency: '$0.00',
    priceWithCurrency: '$0.00',
  },
  alternatives: [
    {
      outbound: {
        id: 333323,
        parentId: 333295,
        status: ExperienceStatus.CONFIRMED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333321,
        parentId: 333295,
        flightLegs: [],
        status: ExperienceStatus.PROPOSAL,
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333324,
        parentId: 333295,
        status: ExperienceStatus.SUGGESTION,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
    {
      outbound: {
        id: 333322,
        parentId: 333295,
        status: ExperienceStatus.CANCELED,
        flightLegs: [],
        title: 'Alternative to flights',
        duration: null,
        dayDifference: null,
      },
      inbound: null,
      localPriceWithCurrency: '$0.00',
      priceWithCurrency: '$0.00',
    },
  ],
};
