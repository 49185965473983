import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment';

@Pipe({
  name: 'resizeImg',
  standalone: true,
})
export class ResizeImagePipe implements PipeTransform {
  cloudinaryURL = environment.cloudinaryURL;

  transform(url: string | null, width?: number, height?: number, transformation?: string): string | null {
    if (url && url.includes(this.cloudinaryURL.substring(7))) {
      // remove protocol - http/https
      const publicPath = url.split('upload/')[1];
      const heightParam = height ? `h_${height}` : '';
      const widthParam = width ? `w_${width}` : '';
      const q = !height || !width || height > 230 || width > 230 ? 'auto' : '100';
      const transformParam = transformation ? transformation : 'c_limit';
      return `${this.cloudinaryURL}${transformParam},fl_progressive,q_${q},f_auto,dpr_auto,${widthParam},${heightParam}/${publicPath}`;
    } else {
      return url;
    }
  }
}
