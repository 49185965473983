import { VGSCardField, VGSFieldTypes, VGSKeyField, VGSKeyNames } from './vgs-field-types.model';

export const FieldNameKeyVinculation: Record<VGSKeyNames, VGSFieldTypes> = {
  [VGSKeyNames.cardNumber]: VGSFieldTypes.cardNumber,
  [VGSKeyNames.cardCvc]: VGSFieldTypes.cardSecurityCode,
  [VGSKeyNames.cardExpirationDate]: VGSFieldTypes.cardExpirationDate,
  [VGSKeyNames.cardMemo]: VGSFieldTypes.text,
  [VGSKeyNames.cardBillingZipcode]: VGSFieldTypes.text,
  [VGSKeyNames.cardName]: VGSFieldTypes.text,
};

export interface VGSFieldsState {
  [VGSKeyNames.cardMemo]?: VGSKeyField;
  [VGSKeyNames.cardName]?: VGSKeyField;
  [VGSKeyNames.cardNumber]: VGSCardField;
  [VGSKeyNames.cardExpirationDate]: VGSKeyField;
  [VGSKeyNames.cardCvc]: VGSKeyField;
  [VGSKeyNames.cardBillingZipcode]?: VGSKeyField;
}

export interface VGSState {
  fields: VGSFieldsState;
  isValid: boolean;
}
