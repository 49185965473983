import { Component, Input } from '@angular/core';
import { IconService } from '../../services/icon.service';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ess-icon-status',
  templateUrl: './icon-status.component.html',
  styleUrls: ['./icon-status.component.scss'],
})
export class IconStatusComponent {
  icon: SafeHtml | null = null;

  @Input() set status(value: string) {
    const key = value ? 'icon_status_' + value.toLowerCase() : null;
    if (key && this.iconService.isIconCreated(key)) {
      this.icon = this.iconService.getSVG(key);
    }
  }

  constructor(private iconService: IconService) {}
}
