import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@environment';
import { MemberExperienceManagerBE, UserBE } from '../models/user-be.model';
import { User } from '../models/user.model';
import { Brand } from '../models/brand.model';
import { MemberExperienceManager } from '../models/member-experience-manager.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user$ = new BehaviorSubject<User | null>(null);

  constructor(private readonly httpClient: HttpClient) {}

  getUser$(): Observable<User> {
    const storedUser: User | null = this.user$.getValue();
    return storedUser ? of(storedUser) : this.fetchUser$();
  }

  removeUser(): void {
    this.user$.next(null);
  }

  private fetchUser$(): Observable<User> {
    const url = `${environment.apiEnv}api/users/me/`;

    return this.httpClient.get<UserBE>(url).pipe(
      map(userBE => this.transformIntoUser(userBE)),
      tap(user => this.user$.next(user)),
    );
  }

  private transformIntoUser(userBE: UserBE): User {
    return {
      avatar: userBE.avatar,
      email: userBE.email,
      firstName: userBE.first_name,
      lastName: userBE.last_name,
      name: `${userBE.first_name} ${userBE.last_name}`.trim(),
      memberExperienceManager:
        userBE.member_experience_manager && this.transformIntoMemberExperienceManager(userBE.member_experience_manager),
      memberSince: userBE.member_since,
      brand: userBE.brand || Brand.ESSENTIALIST,
    };
  }

  private transformIntoMemberExperienceManager(member: MemberExperienceManagerBE): MemberExperienceManager {
    return {
      avatar: member.avatar,
      calendarUrl: member.calendar_url,
      email: member.email,
      firstName: member.first_name,
      lastName: member.last_name,
      name: member.name,
    };
  }
}
