// Used for untyped vgs library (form fields)
export interface VGSKeyField {
  isValid: boolean;
  [key: string]: unknown; // Others not being used
}
export interface VGSCardField extends VGSKeyField {
  cardType: string;
}

export enum VGSFieldTypes {
  cardNumber = 'card-number',
  cardSecurityCode = 'card-security-code',
  cardExpirationDate = 'card-expiration-date',
  ssn = 'ssn',
  password = 'password',
  text = 'text',
  zipCode = 'zip-code',
  postalCode = 'postal-code',
  file = 'file',
  dropdown = 'dropdown',
  checkbox = 'checkbox',
}

export enum VGSKeyNames {
  cardMemo = 'card_memo',
  cardName = 'card_name',
  cardNumber = 'card_number',
  cardExpirationDate = 'card_expiration_date',
  cardCvc = 'card_cvc',
  cardBillingZipcode = 'billing_zipcode',
}
