import { ExperienceStatus } from './experience-status.model';

type StatusOrder = {
  [status in ExperienceStatus]: number;
};

export const STATUS_ORDER_LIST: StatusOrder = {
  [ExperienceStatus.CONFIRMED]: 1,
  [ExperienceStatus.PROPOSAL]: 2,
  [ExperienceStatus.SUGGESTION]: 3,
  [ExperienceStatus.HIDDEN]: 4,
  [ExperienceStatus.CANCELED]: 5,
};
