import { base64PlatinumFont } from './platinum-font';
import { base64UniversFont } from './univers-font';

export const defaultColor = '#303030';
export const errorColor = '#f44336';
export const placeholderColor = '#0000008a';

export const vgsGenericStyle = {
  background: 'transparent',
  lineHeight: 'normal',
  color: `${defaultColor}`,
  fontSize: '16px',
  boxSizing: 'border-box',
  '@font-face': {
    'font-family': 'PlantinMTPro',
    src: `url(data:font/x-font-woff;charset=utf-8;base64, ${base64PlatinumFont}) format('woff')`,
  },
  fontFamily: 'PlantinMTPro',
  letterSpacing: 'normal',
  '-webkit-font-smoothing': 'antialiased',
  '&::placeholder': {
    color: placeholderColor,
  },
};

export const vgsUniversFont = {
  ...vgsGenericStyle,
  '@font-face': {
    'font-family': 'UniversLTStd',
    src: `url(data:font/x-font-woff;charset=utf-8;base64, ${base64UniversFont}) format('woff')`,
  },
  fontFamily: 'UniversLTStd',
};

export const vgsCreditNumberStyle = {
  ...vgsGenericStyle,
  paddingLeft: '45px',
};
